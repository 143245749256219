import React from 'react'
import {graphql, Link} from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
// Import a function to build the blog URL
import { getBlogUrl,
         filterOutDocsWithoutSlugs,
         filterOutDocsPublishedInTheFuture,
 } from '../lib/helpers'
 import tw, { theme, css } from 'twin.macro'
 import { format } from 'date-fns'

// Add “posts” to the GraphQL query
export const query = graphql`
  query CategoryTemplateQuery($id: String!) {
    category: sanityCategory(id: {eq: $id}) {
      title
      description
      posts {
        _id
        title
        publishedAt
        slug {
          current
        }
      }
    }
  }
`
const CategoryPostTemplate = props => {
  const {data = {}, errors} = props
  // Destructure the new posts property from props
  const {title, description, posts} = data.category || {}
  
  return (
    <Layout>
      {errors && <GraphQLErrorList errors={errors} />}
      {!data.category && <p>No category data</p>}
      <SEO title={title + ` notes`} description={description} />
      <section tw="w-full">
        <div
          tw="mt-5 pt-5 md:mt-16 md:pt-20 mx-6 md:mx-auto grid grid-cols-1 md:grid-cols-2 font-serif pb-10 md:pb-16 border-b"
          css={{
            width: 'min(1024px, calc(100% - 48px))',
            gridRowGap: 8,
            borderColor: `${theme`borderColor.accent`}`,
          }}
        >
          <h1 tw="text-3xl lg:text-4xl font-semibold col-span-1 capitalize">{title}</h1>
          <p tw="col-span-1 text-softer prose prose-lg xl:prose-xl">{description}</p>
        </div>
      </section>
      <main
        tw="mt-10 md:mt-24 font-serif mb-24 md:mb-32"
        css={{
          display: 'grid',
          gridTemplateColumns: '1fr min(800px, calc(100% - 48px)) 1fr',
          gridRowGap: 8,
          '& > *': {
            gridColumn: 2,
          },
        }}
      >
        {/*
            If there are any posts, add the heading,
            with the list of links to the posts
          */}
        {posts && (
          <React.Fragment>
            <ul>
              {posts.map(post => (
                <li
                  tw="gap-x-2 flex flex-col md:flex-row-reverse md:place-items-stretch md:items-center mt-4"
                  key={post._id}
                >
                  <div tw="flex-auto md:w-5/6">
                    <h3 tw="prose">
                      <Link
                        to={getBlogUrl(post.slug)}
                        tw="text-xl lg:text-2xl mt-2 underline my-0"
                        css={{
                          color: `${theme`colors.accent`}`,
                          '&:hover': {
                            color: `${theme`colors.primary`}`,
                          },
                        }}
                      >
                        {post.title}
                      </Link>
                    </h3>
                  </div>
                  <div tw="flex-none font-sans text-sm text-softer md:w-1/6">
                    {format(new Date(post.publishedAt), 'do MMM yyyy')}
                  </div>
                </li>
              ))}
            </ul>
          </React.Fragment>
        )}
      </main>
    </Layout>
  )
}

export default CategoryPostTemplate